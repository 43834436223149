
import HelloWorldComponent from '@/components/HelloWorld/index.vue';
import AuthLayout from '@/layouts/Auth/index.vue';
import { defineComponent } from 'vue';
import _ from 'lodash';

export default defineComponent({
    components: {
        HelloWorldComponent,
        AuthLayout,
    },
    data() {
        return {
            email: 'iskorzun@gmail.com',
            password: '123456Seven',
        };
    },
    computed: {
        // user(): object {
        //   return this.$store.getters.getUser;
        // },
        // errors(): [] {
        //   return this.$store.getters.getErrors
        // },
    },
    created: function () {
        //this.$router.push('/wallpapers');
        // let suscribe = this.$store.subscribe((mutation, state) => {
        //     if (mutation.type === 'setUser' && mutation.payload) {
        //         alert('success login!');
        //     }
        // })
    },
    methods: {
        login: function (email, password) {
            this.$store
                .dispatch('auth/login', { email: email, password: password })
                .then(() => {
                    this.$router.push('/feed/media');
                })
                .catch((erorr) => {
                    console.log(erorr);
                });
        },
    },
});
